<template>
  <div class="lds-bars">
    <div></div>
    <div></div>
    <div></div>
  </div>
</template>

<script>
export default {
  name: 'Bars'
}
</script>

<style scoped>
.lds-bars {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-bars div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: rgb(49, 5, 206);
  animation: lds-bars 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-bars div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-bars div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-bars div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-bars {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}
</style>
